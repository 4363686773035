import Grid from '@mui/material/Grid';
import {ProjectCard} from './ProjectCard';
import {useLoadMetadataQuery} from './backend';
import {useStatementStore} from './store';

type SectionProductSelectProps = {
  goForward: () => void;
};

export function SectionProductSelect({ goForward }: SectionProductSelectProps) {
  const { data } = useLoadMetadataQuery();
  const { selectedProduct, setSelectedProduct } = useStatementStore();

  return (
      <Grid container spacing={3}>
        {data?.data.products.map((v) => (
          <Grid item xs={12} key={v.code}>
            <ProjectCard
              name={v.name}
              code={v.code}
              selected={selectedProduct?.code === v.code}
              onClick={() => {
                setSelectedProduct(v);
                goForward();
              }}
            />
          </Grid>
        ))}
      </Grid>
  );
}
