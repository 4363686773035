export interface MetaDataDTO {
    products: LeasingProductDTO[];
}

export interface LCSVerificationInitialData {
    codeLength: number
    countdownInSeconds: number
}

export interface LeasingProductDTO {
    code: string;
    name: string;
    currencies: string[];
    paramConfigs: LeasingProductParamConfigDTO[];
}

export interface LeasingProductParamConfigDTO {
    paramType: LeasingProductParamType;
    rangeType: LeasingProductParamConfigRangeType;
    currency: string;
    from: number;
    to: number;
    minValue: number;
    maxValue: number;
    defaultValue: number;
    editable: boolean;
}

export interface CalculatorRequestDTO {
    currency: string;
    assetsValue: number;
    advanceRate: number;
    issuanceRate: number;
    principalAmount: number;
    numberOfPayments: number;
    monthlyPayment?: number;
    issuanceFee: number;
}

export interface CalculatorResponseDTO {
    monthlyPayment: number;
}

export interface StatementDTO {
    productCode: string;
    clientParams: SubjectDTO;
    financialParams: CalculatorRequestDTO;
    comment: string;
    ipAddress: string;
}

export interface SubjectDTO {
    legalType: SubjectLegalType;
    nationalId: string;
    senderNationalId: string;
    firstname: string;
    lastname: string;
    name: string;
    phone: string;
    email?: string;
}

export enum LeasingProductParamType {
    PRINCIPAL_AMOUNT = 'PRINCIPAL_AMOUNT',
    ADVANCE_RATE = 'ADVANCE_RATE',
    NUMBER_OF_PAYMENTS = 'NUMBER_OF_PAYMENTS',
    INTEREST_RATE = 'INTEREST_RATE',
    ISSUANCE_RATE = 'ISSUANCE_RATE',
}

export enum LeasingProductParamConfigRangeType {
    AMOUNT = 'AMOUNT',
    PERIOD = 'PERIOD',
}

export enum SubjectLegalType {
    INDIVIDUAL = 'INDIVIDUAL',
    LEGAL_ENTITY = 'LEGAL_ENTITY',
}

export interface OtpCreateRequest {
    phoneNumber: string;
    email?: string;
}

export interface OtpVerifyRequest {
    phoneNumber: string;
    code: string;
    email?: string;
}

export interface LCSAddClientApprovalsRequest {
    approvalTypes: string[];
    nationalId: string;
    ipAddress: string;
    approverName: string;
    approverNationalId: string;
}
