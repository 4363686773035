import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { Box, Divider, InputAdornment, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useStatementStore } from './store';
import { FormSelectField } from '../../../shared/input/form/FormSelectField';
import { formatAmount2, getCurrencySymbol } from '../../../utils/financial-utils';
import { FormTextField } from '../../../shared/input/form/FormTextField';
import { useEffect, useState } from 'react';
import { z, ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { CalculatorRequestDTO, LeasingProductDTO } from './model';
import { getStringToNumberPreprocessed } from '../../../utils/validation';
import { getDynamicValidationsForCalculator } from './utils';
import { useLoadMonthlyPaymentMutation } from './backend';
import LoadingButton from '@mui/lab/LoadingButton';
import { Calculate } from '@mui/icons-material';
import { TFunction } from 'i18next';

const CALCULATOR_SCHEMA = (
  currency: string,
  product: LeasingProductDTO | null,
  t: TFunction<'translation'>
) => {
  return z.object(
    Object.entries({
      assetsValue: z.number().positive({ message: t('VALIDATION_POSITIVE_AMOUNT')! }),
      principalAmount: getDynamicValidationsForCalculator('principalAmount', currency, product, t),
      advanceRate: getDynamicValidationsForCalculator('advanceRate', currency, product, t),
      numberOfPayments: getDynamicValidationsForCalculator(
        'numberOfPayments',
        currency,
        product,
        t
      ),
    }).reduce((previousValue: Record<string, ZodType>, currentValue) => {
      previousValue[currentValue[0]] = getStringToNumberPreprocessed(currentValue[1]);
      return previousValue;
    }, {})
  );
};

export function SectionCalculator() {
  const { t } = useTranslation();
  const theme = useTheme();
  const higherThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  const { selectedProduct, calculatorInfo, setCalculatorInfo } = useStatementStore();

  const [schema, setSchema] = useState(CALCULATOR_SCHEMA('GEL', null, t));

  const { control, watch, formState, setValue, clearErrors, trigger, handleSubmit } =
    useForm<CalculatorRequestDTO>({
      resolver: zodResolver(schema),
      defaultValues: calculatorInfo,
      mode: 'onTouched',
    });

  const { currency, principalAmount, assetsValue, advanceRate, issuanceFee, issuanceRate } =
    watch();

  // calculate secondary amounts
  useEffect(() => {
    setValue('principalAmount', assetsValue - assetsValue * 0.01 * advanceRate, {
      shouldDirty: false,
      shouldValidate: true,
    });
  }, [advanceRate, assetsValue, setValue]);
  useEffect(() => {
    setValue('issuanceFee', principalAmount * 0.01 * issuanceRate);
  }, [principalAmount, issuanceRate, setValue]);

  // update validation schema
  useEffect(() => {
    setSchema(CALCULATOR_SCHEMA(currency, selectedProduct, t));
  }, [currency, selectedProduct, t]);

  useEffect(
    () => {
      const subscription = watch(() =>
        setCalculatorInfo({
          ...calculatorInfo,
          monthlyPayment: undefined,
        })
      );
      return () => subscription.unsubscribe();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch]
  );

  useEffect(
    () => {
      const relevantConfigs = selectedProduct?.paramConfigs.filter((v) => v.currency === currency);
      const numPayments = relevantConfigs?.find((v) => v.paramType === 'NUMBER_OF_PAYMENTS');
      const advRate = relevantConfigs?.find((v) => v.paramType === 'ADVANCE_RATE');
      const issuanceRate = relevantConfigs?.find((v) => v.paramType === 'ISSUANCE_RATE');

      setValue('numberOfPayments', numPayments?.defaultValue ?? numPayments?.minValue ?? 1, {
        shouldDirty: false,
      });
      setValue('advanceRate', advRate?.defaultValue ?? advRate?.minValue ?? 0, {
        shouldDirty: false,
      });
      setValue('issuanceRate', issuanceRate?.defaultValue ?? issuanceRate?.minValue ?? 0, {
        shouldDirty: false,
      });

      clearErrors();
      trigger('principalAmount');
      trigger('advanceRate');
      trigger('numberOfPayments');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schema]
  );

  const mutation = useLoadMonthlyPaymentMutation(watch(), setCalculatorInfo);

  const currencySymbol = getCurrencySymbol(currency ?? '');
  const principalAmountError = formState.errors.principalAmount?.message;

  return (
    <>
      {/*<Typography variant="h6" mb={3}>*/}
      {/*  {t('enterAmounts')}*/}
      {/*</Typography>*/}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(() => {
            mutation.mutate({
              request: watch(),
              productCode: selectedProduct?.code ?? '',
            });
          })();
        }}
      >
        <List disablePadding>
          <ListItem sx={{ py: 1, px: 0 }} key={'currency'}>
            <ListItemText primary={t('currency')} />
            <Box>
              <FormSelectField
                id={'currency'}
                control={control}
                menuItems={
                  selectedProduct?.currencies.map((v) => ({
                    name: v,
                    value: v,
                  })) ?? []
                }
                sx={{ width: 70 }}
                variant={'standard'}
                name={'currency'}
                placeholder={'0'}
              />
            </Box>
          </ListItem>
          <ListItem sx={{ pt: 1, px: 0 }} key={'amount'}>
            <ListItemText primary={t('assetsValue')} className={'hidden sm:block'} />
            <FormTextField
              control={control}
              id={'assetsValue'}
              name={'assetsValue'}
              sx={higherThanSm ? { minWidth: 170 } : undefined}
              fullWidth={!higherThanSm}
              variant={'standard'}
              label={!higherThanSm ? t('assetsValue') : undefined}
              type={'number'}
              InputProps={{
                endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
              }}
            />
          </ListItem>
          <ListItem sx={{ py: 1, px: 0 }} key={'advance'}>
            <ListItemText primary={t('advance')} />
            <FormTextField
              control={control}
              id={'advanceRate'}
              name={'advanceRate'}
              sx={{ width: 70 }}
              variant={'standard'}
              placeholder={'0'}
              type={'number'}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </ListItem>
          <ListItem sx={{ py: 1, px: 0 }} key={'numberOfPayments'}>
            <ListItemText primary={t('numberOfPayments')} />
            <FormTextField
              control={control}
              id={'numberOfPayments'}
              name={'numberOfPayments'}
              sx={{ width: 70 }}
              variant={'standard'}
              type={'number'}
              placeholder={'1'}
              InputProps={{
                endAdornment: <InputAdornment position="end">{t('month')}</InputAdornment>,
              }}
            />
          </ListItem>
          <ListItem
            sx={{
              px: 0,
              pt: 1,
            }}
            key={'remainingPrincipal'}
            disableGutters
            disablePadding
          >
            <ListItemText
              sx={{ pl: higherThanSm ? 6 : 1 }}
              secondary={t('remainingPrincipal') + ':'}
            />
            <div
              style={{
                color: principalAmountError
                  ? theme.palette.error.dark
                  : theme.palette.text.secondary,
              }}
              className={'text-right text-[12px] font-light'}
            >
              {formatAmount2(principalAmount)}
              {currencySymbol}
              <div
                style={{ color: theme.palette.error.main }}
                // className={'flex flex-row align-middle justify-end content-center gap-1 w-40'}
              >
                {principalAmountError}
              </div>
            </div>
          </ListItem>
          {issuanceFee > 0 && (
            <ListItem sx={{ pb: 1, px: 0 }} key={'issuanceFee'} disableGutters disablePadding>
              <ListItemText sx={{ pl: higherThanSm ? 6 : 1 }} secondary={t('issuanceFee') + ':'} />
              <div
                style={{ color: theme.palette.text.secondary }}
                className={'text-right text-[12px] font-light'}
              >
                {formatAmount2(issuanceFee)}
                {currencySymbol}
              </div>
            </ListItem>
          )}
          <Divider sx={{ my: 1, py: 1 }} />
          <Stack direction={higherThanSm ? 'row' : 'column'} spacing={2}>
            <LoadingButton
              type={'submit'}
              variant={'contained'}
              disabled={!formState.isValid}
              fullWidth={!higherThanSm}
              loading={mutation.isLoading}
              startIcon={<Calculate />}
            >
              <span>{t('calculate')}</span>
            </LoadingButton>
            {calculatorInfo.monthlyPayment !== undefined ? (
              <ListItem sx={{ px: 0 }} key={'monthlyPayment'}>
                <ListItemText primary={t('monthlyPayment')} />
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {formatAmount2(calculatorInfo.monthlyPayment)}
                  {currencySymbol}
                </Typography>
              </ListItem>
            ) : (
              <></>
            )}
          </Stack>
          <Stack
            mt={3}
            fontSize={'small'}
            textAlign={higherThanSm ? 'end' : 'center'}
            color={(t) => t.palette.text.secondary}
          >
            {t('completeForDetails')}
          </Stack>
        </List>
      </form>
    </>
  );
}

// completeForDetails
